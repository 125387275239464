import { Email } from "./Email";

function App() {
  return (
    <div className="w-full inset-0 h-screen flex bg-slate-800">
      <div className="m-auto text-white font-semibold text-xl text-center">
        <h1 className="text-3xl pb-3 font-bold tracking-wider">CGreen ApS</h1>
        <p>Casper Green</p>
        <Email user="repsac" domain="neergc" tld="kd" />
      </div>
    </div>
  );
}

export default App;
