/* eslint-disable jsx-a11y/anchor-has-content */
interface Props {
  user: string;
  domain: string;
  tld: string;
}

const reverse = (data: string) => data.split("").reverse().join("");

export const Email = ({ user, domain, tld }: Props) => {
  const email = `${user}@${domain}.${tld}`;
  const handleMouseOverEmail = (e: React.MouseEvent<HTMLAnchorElement>) => {
    (e.target as HTMLAnchorElement).href = `mailto:${reverse(user)}@${reverse(
      domain
    )}.${reverse(tld)}`;
  };
  const handleMouseLeaveEmail = (e: any) => {
    e.target.href = `mailto:${email}`;
  };
  return (
    <a
      href={`mailto:${email}`}
      onMouseEnter={handleMouseOverEmail}
      onMouseLeave={handleMouseLeaveEmail}
      data-user={user}
      data-website={domain}
      data-tld={tld}
      target="_blank"
      rel="noreferrer"
      className="email"
    ></a>
  );
};
